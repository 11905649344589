import { AddressDto, GematikProfile, PartnerDto, PartnerRoleDto } from "../../models/profile.model";
import * as fromProfile from "../actions/profile.actions";
import { Action, ActionCreator, createReducer, on, ReducerTypes } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { AssignmentGroupIdentityExtendedDto } from "../../models/profile.model";

export const FEATURE_KEY = "profile";

export interface ProfileState extends EntityState<GematikProfile> {
  loading: boolean;
  loaded: boolean;
}

export interface AgiState extends EntityState<AssignmentGroupIdentityExtendedDto> {
  loading: boolean;
  loaded: boolean;
}

export interface AddressState extends EntityState<AddressDto> {
  loading: boolean;
  loaded: boolean;
}

export interface PartnerState extends EntityState<PartnerDto> {
  loading: boolean;
  loaded: boolean;
}

export interface PartnerRoleState extends EntityState<PartnerRoleDto> {
  loading: boolean;
  loaded: boolean;
}

export interface State {
  profile: ProfileState;
  agis: AgiState;
  addresses: AddressState;
  partners: PartnerState;
  partnerRoles: PartnerRoleState;
}

export const profileAdapter: EntityAdapter<GematikProfile> = createEntityAdapter<GematikProfile>();
export const agiAdapter: EntityAdapter<AssignmentGroupIdentityExtendedDto> =
  createEntityAdapter<AssignmentGroupIdentityExtendedDto>({
    sortComparer: (a: AssignmentGroupIdentityExtendedDto, b: AssignmentGroupIdentityExtendedDto) =>
      a.identityRole.localeCompare(b.identityRole),
  });
export const addressAdapter: EntityAdapter<AddressDto> = createEntityAdapter<AddressDto>();
export const partnerAdapter: EntityAdapter<PartnerDto> = createEntityAdapter<PartnerDto>();
export const partnerRoleAdapter: EntityAdapter<PartnerRoleDto> =
  createEntityAdapter<PartnerRoleDto>({
    selectId: (model: PartnerRoleDto) => `${model.id}.${model.partnerId}`,
  });

export const initialProfileState: ProfileState = profileAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialAgiState: AgiState = agiAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialAddressesState: AddressState = addressAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialPartnerState: PartnerState = partnerAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialPartnerRoleState: PartnerRoleState = partnerRoleAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const initialState: State = {
  profile: initialProfileState,
  agis: initialAgiState,
  addresses: initialAddressesState,
  partners: initialPartnerState,
  partnerRoles: initialPartnerRoleState,
};

export const profileReducers: ReducerTypes<State, ActionCreator[]>[] = [
  on(fromProfile.LoadProfile, (state) => ({
    ...state,
    profile: { ...state.profile, loading: true, loaded: false },
  })),
  on(fromProfile.LoadProfileSuccess, (state, { payload }) => ({
    ...state,
    profile: profileAdapter.addOne(payload, { ...state.profile, loading: false, loaded: true }),
  })),
  on(fromProfile.LoadProfileFail, (state) => ({
    ...state,
    profile: { ...state.profile, loading: false, loaded: false },
  })),
  on(fromProfile.UpdateProfile, (state) => ({
    ...state,
    profile: { ...state.profile, loading: true, loaded: false },
  })),
  on(fromProfile.UpdateProfileSuccess, (state, { payload }) => ({
    ...state,
    profile: profileAdapter.updateOne(
      { id: payload.id, changes: payload },
      { ...state.profile, loading: false, loaded: true },
    ),
  })),
  on(fromProfile.UpdateProfileFail, (state) => ({
    ...state,
    profile: { ...state.profile, loading: false, loaded: false },
  })),
  on(fromProfile.LoadAgis, (state) => ({
    ...state,
    agis: { ...state.agis, loading: true, loaded: false },
  })),
  on(fromProfile.LoadAgisSuccess, (state, { payload }) => ({
    ...state,
    agis: agiAdapter.addMany(payload, { ...state.agis, loading: false, loaded: true }),
  })),
  on(fromProfile.LoadAgisFail, (state) => ({
    ...state,
    agis: { ...state.agis, loading: false, loaded: false },
  })),
  on(fromProfile.LoadPartner, (state) => ({
    ...state,
    partners: { ...state.partners, loading: true, loaded: false },
  })),
  on(fromProfile.LoadPartnerSuccess, (state, { payload }) => ({
    ...state,
    partners: partnerAdapter.addOne(payload, { ...state.partners, loading: false, loaded: true }),
  })),
  on(fromProfile.LoadPartnerFail, (state) => ({
    ...state,
    partners: { ...state.partners, loading: false, loaded: false },
  })),
  on(fromProfile.UpdatePartner, (state) => ({
    ...state,
    partners: { ...state.partners, loading: true, loaded: false },
  })),
  on(fromProfile.UpdatePartnerSuccess, (state, { payload }) => {
    const update: Update<PartnerDto> = {
      id: payload.id,
      changes: { ...payload },
    };
    return {
      ...state,
      partners: partnerAdapter.updateOne(update, {
        ...state.partners,
        loading: false,
        loaded: true,
      }),
    };
  }),
  on(fromProfile.UpdatePartnerFail, (state) => ({
    ...state,
    partners: { ...state.partners, loading: false, loaded: false },
  })),
  on(fromProfile.LoadAgiAddresses, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: true, loaded: false },
  })),
  on(fromProfile.LoadAgiAddressesSuccess, (state, { payload }) => ({
    ...state,
    addresses: addressAdapter.addMany(payload, {
      ...state.addresses,
      loading: false,
      loaded: true,
    }),
  })),
  on(fromProfile.LoadAgiAddressesFail, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: false, loaded: false },
  })),
  on(fromProfile.AddAgiAddress, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: true, loaded: false },
  })),
  on(fromProfile.AddAgiAddressSuccess, (state, { payload }) => ({
    ...state,
    addresses: addressAdapter.addOne(payload, {
      ...state.addresses,
      loading: false,
      loaded: true,
    }),
  })),
  on(fromProfile.AddAgiAddressFail, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: false, loaded: false },
  })),
  on(fromProfile.UpdateAgiAddress, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: true, loaded: false },
  })),
  on(fromProfile.UpdateAgiAddressSuccess, (state, { payload }) => {
    const update: Update<AddressDto> = {
      id: payload.id,
      changes: { ...payload },
    };
    return {
      ...state,
      addresses: addressAdapter.updateOne(update, {
        ...state.addresses,
        loading: false,
        loaded: true,
      }),
    };
  }),
  on(fromProfile.UpdateAgiAddressFail, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: false, loaded: false },
  })),
  on(fromProfile.DeleteAgiAddress, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: true, loaded: false },
  })),
  on(fromProfile.DeleteAgiAddressSuccess, (state, { payload }) => ({
    ...state,
    addresses: addressAdapter.removeOne(payload.id, {
      ...state.addresses,
      loading: false,
      loaded: true,
    }),
  })),
  on(fromProfile.DeleteAgiAddressFail, (state) => ({
    ...state,
    addresses: { ...state.addresses, loading: false, loaded: false },
  })),
  on(fromProfile.LoadPartnerRoles, (state) => ({
    ...state,
    partnerRoles: { ...state.partnerRoles, loading: true, loaded: false },
  })),
  on(fromProfile.LoadPartnerRolesSuccess, (state, { payload }) => ({
    ...state,
    partnerRoles: partnerRoleAdapter.addMany(payload, {
      ...state.partnerRoles,
      loading: false,
      loaded: true,
    }),
  })),
  on(fromProfile.LoadPartnerRolesFail, (state) => ({
    ...state,
    partnerRoles: { ...state.partnerRoles, loading: false, loaded: false },
  })),
];

const profileReducer = createReducer<State>(initialState, ...profileReducers);

export function reducer(state: State, action: Action) {
  return profileReducer(state, action);
}
