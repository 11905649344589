import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, take } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { GemDialogComponent, GemTimelineDialogComponent } from "gematik-form-library";
import { TranslateService } from "@ngx-translate/core";

export interface TimelineResponse {
  history: Timeline[];
  currentActivity: Timeline[];
  milestones: Timeline[];
  events: Timeline[];
}

export interface Timeline {
  activityId: string;
  label: string;
  description: string;
  created: Date;
  completed: Date;
  status: string;
}

@Injectable()
export class TimelineService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {}

  private getTimeline(endpoint: string, buid: string, lang: string): Observable<TimelineResponse> {
    return this.http.get<TimelineResponse>(`${endpoint}/public/timeline/${buid}?lang=${lang}`);
  }

  showTimeline(backendUrl: string, buid: string, lang: string): void {
    const sub = this.getTimeline(backendUrl, buid, lang)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const timeline: TimelineResponse = res;
          if (timeline) {
            const events: any[] = [];
            res.events.forEach((e) => {
              events.push({
                label: e.label,
                description: e.description,
                date: e.created ? e.created : e.completed,
                status: e.status,
              });
            });
            const dialogRef = this.dialog.open(GemTimelineDialogComponent, {
              width: "30%",
              data: {
                title: this.translateService.instant("timeline.title"),
                legendOpen: this.translateService.instant("timeline.legendOpen"),
                legendCurrent: this.translateService.instant("timeline.legendCurrent"),
                legendFinished: this.translateService.instant("timeline.legendFinished"),
                events,
              },
            });
          }
        },
        error: (error) => {
          const dialogRef = this.dialog.open(GemDialogComponent, {
            width: "40%",
            data: {
              title: this.translateService.instant("timeline.errorTitle"),
              text: this.translateService.instant("timeline.errorMessage"),
              style: {
                "text-align": "center",
              },
            },
          });
        },
      });
  }
}
